import React, {useState} from 'react';
import './index.scss';
import Logo from "../../../assets/images/logo.svg"
import LogoWhite from "../../../assets/images/logo_white.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Header(props) {
    const [show, setShow] = useState(false);
    return <>
        <div className={`mobile-header ${show ? "active" : ""}`}>
            <div className="container">
                <div className="mobile-header__wrapper">
                    <div className="toggler" onClick={() => setShow(!show)}>
                        <span></span><span></span><span></span>
                    </div>
                    {show ? <img className="mobile-header__logo" src={LogoWhite}/> :
                        <img className="mobile-header__logo" src={Logo}/>}
                    <div className="nothing"></div>
                </div>
            </div>
        </div>
        <header className={`header ${show ? "active" : ""}`}>
            <div className="container">
                <div className="header__content">
                    <img className="header__logo" src={Logo}/>
                    <nav className="nav">
                        <ul className="nav__list">
                            <AnchorLink
                                offset="100"
                                href="#tasks"
                                className="nav__item"
                                onClick={() => setShow(false)}
                            >
                                Задачи продукта
                            </AnchorLink>
                            <AnchorLink
                                offset="100"
                                href="#arch"
                                className="nav__item"
                                onClick={() => setShow(false)}
                            >
                                Архитектура
                            </AnchorLink>
                            <AnchorLink
                                offset="100"
                                href="#features"
                                className="nav__item"
                                onClick={() => setShow(false)}
                            >
                                Возможности
                            </AnchorLink>
                            <AnchorLink
                                offset="100"
                                href="#reviews"
                                className="nav__item"
                                onClick={() => setShow(false)}
                            >
                                Отзывы
                            </AnchorLink>
                            <a href="/public/Elemento_Mostro_PIM.pdf" download className="nav__item">Презентация</a>
                        </ul>
                    </nav>
                    <AnchorLink href="#form" offset="100" className="btn btn_primary btn_light hidden_xs hidden_md">
                        Связаться с нами
                    </AnchorLink>
                    <AnchorLink href="#form" offset="100" className="btn btn_primary hidden_lg btn_block "
                                onClick={() => setShow(false)}>
                        Заказать демо
                    </AnchorLink>
                </div>
            </div>
        </header>
    </>
}

export default Header