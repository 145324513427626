import React from 'react';
import './index.scss';
import Pie from '../../../assets/images/pie.svg'
import Base2 from '../../../assets/images/base2.svg'
import Filepic from '../../../assets/images/file.svg'
import Trello from '../../../assets/images/trello.svg'
import Cart from '../../../assets/images/cart.svg'
import Phone from '../../../assets/images/phone.svg'
import Doc from '../../../assets/images/doc.svg'
import Bag from '../../../assets/images/bag.svg'

function Architecture() {
    return <section className="arch" id="arch">
        <div className="container">
            <div className="arch__block">
                <h3>Архитектура</h3>
                <div className="arch__content">
                    {/* <div className="arch__pim hidden_lg">
                        <h4>Elemento PIM</h4>
                    </div> */}
                    <div className="arch__col arch__col_start">
                        <div className="arch__col-item">
                            <p>ERP-система</p>
                            <img src={Pie} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Данные из других систем</p>
                            <img src={Base2} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Файлы (excel и др.)</p>
                            <img src={Filepic} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Медиа-контент</p>
                            <img src={Trello} alt="" />
                        </div>
                    </div>
                    <div className="arch__pim">
                        <h4 >Elemento PIM</h4>
                        <div className="arch__pim-item">
                            <div>MDM/PIM</div>
                            <p>Управление
                                мастер данными</p>
                        </div>
                        <div className="arch__pim-item">
                            <div>DAM</div>
                            <p>Управление
                                медиа-контентом</p>
                        </div>
                        <div className="arch__pim-item">
                            <div>Автоматизация</div>
                            <p>Правила автоматического заполнения данных</p>
                        </div>
                        <div className="arch__pim-item">
                            <div>Контроль процессов</div>
                            <p>Трекер задач и
                                ВРМ движок</p>
                        </div>
                    </div>
                    <div className="arch__col arch__col_end">
                        <div className="arch__col-item">
                            <p>Сайты и маркетплейсы</p>
                            <img src={Cart} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Мобильное приложение</p>
                            <img src={Phone} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Печатные каталоги</p>
                            <img src={Doc} alt="" />
                        </div>
                        <div className="arch__col-item">
                            <p>Розница</p>
                            <img src={Bag} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Architecture