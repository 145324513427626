import React, { useState } from 'react';
import './index.scss';
import Axios from 'axios';
import Figure from "../../../assets/images/figure.png"
import InputMask from 'react-input-mask';
import {  useToasts } from 'react-toast-notifications';
import elemento_systems from '../../../assets/documents/elemento_systems.pdf'

function Form() {
    const [checked, setChecked] = useState(false);
    const [fields, setFields] = useState([
        {
            name: "name",
            placeholder: "Ваше имя",
            required: true,
            type: "text",
            value: ""
        },
        {
            name: "company",
            placeholder: "Название компании",
            required: true,
            type: "text",
            value: ""
        },
        {
            name: "phone",
            placeholder: "Номер телефона",
            required: false,
            type: "phone",
            value: ""
        },
        {
            name: "email",
            placeholder: "E-mail",
            required: true,
            type: "email",
            value: ""
        },
        {
            name: "comment",
            placeholder: "Комментарий",
            required: false,
            type: "textarea",
            value: ""
        },
    ])

    const { addToast } = useToasts();

    const handleChange = index => e => {
        let newArr = [...fields];
        newArr[index]['value'] = e.target.value;
        setFields(newArr);
    }
    const clearForm = () => {
        const clearedFields = fields.map((el) => {
            el.value = "";
            return el
        })
        setFields(clearedFields)
    }
    Axios.defaults.headers.post['X-CSRFToken'] = document.querySelectorAll("[name=csrfmiddlewaretoken]")[0]?.value // for POST requests
    const submitForm = e => {
        e.preventDefault();
        const data = {
            csrfmiddlewaretoken: document.querySelectorAll("[name=csrfmiddlewaretoken]")[0]?.value

        }

        fields.map((el) => {
            if (el.value) {
                data[el.name] = el.value
            }
        })
        Axios.post('/contacts/', data).then((res) => {
            if (res.data.status === 'ok') {
                addToast("Спасибо! Мы с вами свяжемся!", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                clearForm()
            } else {
                addToast("Внутренняя ошибка. Мы уже работаем над проблемой.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        }).catch(err => {
            addToast("Внутренняя ошибка. Мы уже работаем над проблемой.", {
                appearance: 'error',
                autoDismiss: true,
            })
        });

    }
    return <section className="form" id="form">
        <div className="container">
            <h3>Обсудим ваш проект?</h3>
            <div className="form__row">
                <img src={Figure} className="form__figure hidden_xs hidden_md" alt="" />


                <form className="form__fields" onSubmit={(e) => { submitForm(e) }}>
                    <p className="form__text">Ответим на все ваши вопросы и проведем демо</p>
                    {fields.map((el, i) => {
                        if (el.type == 'textarea') {
                            return <textarea name={el.name} placeholder={el.placeholder} maxLength="200" onChange={handleChange(i)} value={el.value} />
                        } else if (el.type == 'phone') {
                            return <InputMask mask="7(999)999-99-99" placeholder={el.placeholder} onChange={handleChange(i)} required={el.required} value={el.value} />
                        } else if (el.type == 'email') {
                            return <input name={el.name} placeholder={el.placeholder} required={el.required} type="email" onChange={handleChange(i)} value={el.value} />
                        }
                        return <input name={el.name} placeholder={el.placeholder} required={el.required} type="text" onChange={handleChange(i)} value={el.value} />
                    })}
                    <div className="checkbox-button">
                        <label
                            className="checkbox-button__label checkbox-button__label_with_description"
                            htmlFor="agree"
                        >
                            <input
                                type="checkbox"
                                className="checkbox-button__input"
                                id="agree"
                                value="true"
                                checked={checked}
                                required
                                onChange={(event) => setChecked(event.target.checked)}
                            />
                            <span className="checkbox-button__mark" />
                            <p className="checkbox-button__title">Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c <a href={elemento_systems} className="text text_primary download">политикой конфиденциальности</a></p>
                        </label>
                    </div>
                    <div className="form__footer">
                        <button className="btn btn_primary" type="submit" disabled={!checked}>Отправить</button>
                    </div>
                </form>

            </div>
        </div>
    </section>
}

export default Form
