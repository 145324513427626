import React from 'react';
import './index.scss';

function Compare() {
    const tableHeadings = [
        "", "Akeneo \n Enterprice \n (Франция)", "Akeneo \n Community \n (Франция)", "Pimcore \n (Германия)", "Elemento \n (Россия)"
    ]
    const tableLines = [[
        "Хранение данных в разрезе каналов продаж и локализаций",
        "Да",
        "Да",
        "Да",
        "Да",
    ], [
        "Контроль полноты данных",
        "Да",
        "Да",
        "Нет",
        "Да",
    ],
        [
            "Автоматизация рутинных задач",
            "Да",
            "Нет",
            "Нет",
            "Да",
        ],
        [
            "Категории",
            "Да",
            "Да",
            "Да",
            "Да",
        ],
        [
            "Справочники",
            "Да",
            "Нет",
            "Да",
            "Да",
        ],
        [
            "Медиатека",
            "Да",
            "Нет",
            "Да",
            "Да",
        ],
        [
            "История изменений",
            "Да",
            "Нет",
            "Да",
            "Да",
        ],
        [
            "Настраиваемые права доступа",
            "Да",
            "Да",
            "Да",
            "Да",
        ],
        [
            "Выстраивание бизнес-процессов",
            "Нет",
            "Нет",
            "Да",
            "Да, BPMN",
        ],
        [
            "Нормализация данных с помощью нейросетей",
            "Нет",
            "Нет",
            "Нет",
            <span>Да, через <a target='_blank' rel="noreferrer" href={"https://nerolabai.ru"}>NerolabAI</a></span>,
        ],
        [
            "Генерация описаний по характеристикам с помощью нейросетей",
            "Нет",
            "Нет",
            "Нет",
            <span>Да, через <a target='_blank' rel="noreferrer" href={"https://nerolabai.ru"}>NerolabAI</a></span>,
        ],
    ]
    return <section className="compare" id="compare">
        <div className="container">
            <h3>
                Сравнение основного функционала с зарубежными аналогами
            </h3>
            <div className="table">
                <div className="thead">
                    <div className="tr">
                        {tableHeadings.map((el) => <div className="th">{el}</div>)}
                    </div>
                </div>
                <div className="tbody">
                    {tableLines.map((line) => {
                        return <div className="tr">
                            {line.map((el, i) => <div
                                className={`th ${el !== "Нет" && i !== 0 ? "primary" : "default"}`}>{el}</div>)}
                        </div>
                    })}
                </div>
            </div>
        </div>
    </section>
}

export default Compare