import React, { useState } from 'react';
import './index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Feature2 from '../../../assets/images/feature2.png'
import Feature3 from '../../../assets/images/feature3.png'
import Feature4 from '../../../assets/images/feature4.png'
import Feature5 from '../../../assets/images/feature5.png'
import Feature6 from '../../../assets/images/feature6.png'
import Feature7 from '../../../assets/images/feature7.png'

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
function Features() {
    const [open, setOpen] = React.useState(false);
    const [checkedImage, setCheckedImage] = useState('');
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const items = [
        {
            title: "Хранение данных в разрезе каналов и локализаций",
            description: '<div>Управление характеристиками для каждого канала из одного интерфейса. Информация может храниться на разных языках.</div>',
            picture: Feature3,
            alt: "Управление характеристиками для каждого канала из одного интерфейса. Информация может храниться на разных языках."
        },
        {
            title: "Автоматизация рутинных задач",
            description: '<div>Автоматическое заполнение характеристик, привязка категории или фотографии к товару  экономят трудозатраты контент-менеджеров и сотрудников фотостудии.  В среднем товар выводится <span class="text text_primary">на сайт за 1-2 дня вместо 4-5 дней</span>.</div>',
            picture: Feature2,
            alt: "Автоматическое заполнение характеристик, привязка категории или фотографии к товару  экономят трудозатраты контент-менеджеров и сотрудников фотостудии.  В среднем товар выводится на сайт за 1-2 дня вместо 4-5 дней."
        },
        {
            title: "Справочники",
            description: '<div>Благодаря справочникам сокращается ручной ввод. Данные нормализуются, их внесение ускоряется, а ошибок становится меньше.</div>',
            picture: Feature5,
            alt: "Благодаря справочникам сокращается ручной ввод. Данные нормализуются, их внесение ускоряется, а ошибок становится меньше."
        },
        {
            title: "Медиатека",
            description: '<div>Хранение и структурирование медиа-файлов разных форматов (изображения, документы, видео).</div>',
            picture: Feature4,
            alt: "Хранение и структурирование медиа-файлов разных форматов (изображения, документы, видео"
        },
        {
            title: "Интеграция с маркетплейсами",
            description: '<div>Elemento PIM поддерживает экспорт на все основные маркетплейсовые площадки. Актуальная информация в центральной системе — актуальная информация во всех каналах.</div>',
            picture: Feature6,
            alt: "Elemento PIM поддерживает экспорт на все основные маркетплейсовые площадки."
        },
        {
            title: "Выстраивание бизнес-процессов и трекер задач",
            description: '<div>Организация ваших процессов и контроль работы сотрудников. Убирает лишнее взаимодействие между отделами, <span class="text text_primary">экономит от 30% времени</span>, в зависимости от БП компании. Задачи будут автоматически создаваться согласно бизнес-процессу. Задачи можно ставить и в ручном режиме.</div>',
            picture: Feature7,
            alt: "Организация ваших процессов убирает лишнее взаимодействие между отделами, экономит время от 30%."
        },
    ]
    return <><section className="features" id="features">
        <div className="container">
            <h3 className="hidden_lg">Основные возможности</h3>
            <div className="features__content">
                <h3 className="hidden_xs hidden_md">Основные возможности</h3>
                <Slider
                    dots={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                
                >
                    {items.map((el) => {
                        return <div className="features__block">
                            <div className="features__inner">
                                <div className="features__text">
                                    <div className="features__title">{el.title}</div>
                                    <div className="features__subtitle" dangerouslySetInnerHTML={{ __html: el.description }} />
                                </div>
                                <img style={{cursor: "pointer"}} src={el.picture} onClick={() => {
                                    setCheckedImage(el.picture)
                                    handleOpen()

                                }} alt={el.alt} className="features__image" />
                            </div>
                        </div>
                    })}
                </Slider>
            </div>
        </div>
    </section>
        {open &&
            <Rodal visible={open} onClose={handleClose} width="800" height="600">
                {/* <div> */}
                <img src={checkedImage} className="screenshot" alt="" />

                {/* </div> */}
            </Rodal>
        }
    </>
}

export default Features