import React from 'react';
import './index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LogoBy from "../../../assets/images/logo_by.png"
import LogoGm from "../../../assets/images/logo_gm.png"

function Reviews() {

    const items = [
        {
            logo: LogoGm,
            name: "Галамарт",
            agent: "Игорь Джабраилов",
            position: "Менеджер по интернет-коммуникациям",
            text: "С помощью Elemento PIM мы эффективнее работаем с продуктовым контентом, а также получаем доступ к точной и подробной аналитике. В результате работа, которая раньше могла растянуться на недели, теперь занимает считанные часы."
        },
        {
            logo: LogoBy,
            name: "BY-SHOP",
            agent: "Максим Платонов",
            position: "Руководитель направления разработки",
            text: "В результате интеграции Elemento PIM существенно повысилась эффективность цепочки наполнения товарной матрицы: увеличилась скорость заполнения карточек и стало меньше ошибок благодаря автоматизации процесса. Продукт работает быстро, без нареканий, а  на вопросы по использованию оперативно отвечает служба поддержки."
        },
        // {
        //     logo: LogoCd,
        //     name: "СДЭК Маркет",
        //     agent: "Павел Фисенко",
        //     position: "CIO Сдэк Маркет",
        //     text: "С внедрением Elemento PIM процесс описания товаров стал прозрачнее и эффективнее. Благодаря настроенным шаблонам описание каждого типа товара стандартизировано, и в данных больше нет ошибок. Стало легче контролировать работу контент-менеджеров и назначать им задачи на описание новых позиций."
        // },
        // {
        //     logo: LogoDf,
        //     name: "Дом Фарфора",
        //     agent: "Романов Роман",
        //     position: "Head of Content and Digital operation",
        //     text: "Раньше на обновление и создание контента для товаров могло уйти до 3 дней. Тексты создавали копирайтеры, характеристики поступали из департамента НСИ, изображения из департамента продакшена. Эту информацию нужно было объединить и проверить, при этом часто возникали проблемы с отображением данных.\n" +
        //         "С Elemento PIM вся информация о каждом SKU находится в одном месте и мы можем редактировать ее в режиме реального времени."
        // },
        // {
        //     logo: LogoWo,
        //     name: "Williams et Oliver",
        //     position: "E-commerce director",
        //     agent: "Александр Филипов",
        //     text: "С Elemento PIM можно аккумулировать данные о товарах в одном месте, быстро вносить изменения в карточки, соблюдать стандарты представления продукции в своих каналах и на площадках партнеров, проверять информацию о товарах на страницах онлайн-ритейла. Теперь каждое подразделение может добавлять и изменять информацию самостоятельно, в результате контент становится детальнее и качественнее, возрастает доверие покупателя к ритейлеру и вероятность выбора этого товара. Это существенно влияет на продажи."
        // },
    ]
    return <section className="reviews" id="reviews">
        <h3>Отзывы</h3>
        <Slider
            dots={true}
            infinite={true}
            // autoplay={true}
            // autoplaySpeed={5000}
            // speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            responsive={[
                {
                    breakpoint: 1880,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        adaptiveHeight: true,
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        adaptiveHeight: true,
                    }
                },
            ]}
        >
            {items.map((el) => {
                return <div className="reviews__block">
                    <div className="reviews__header">
                        <img src={el.logo} className="reviews__logo" />
                        <div className="reviews__info">
                            <div className="reviews__name">{el.name}</div>
                            <div className="reviews__agent">{el.agent}</div>
                            <div className="reviews__position">{el.position}</div>
                        </div>
                    </div>
                    <div className="reviews__text">
                        "{el.text}"
                    </div>
                </div>
            })}
        </Slider>

    </section>
}

export default Reviews