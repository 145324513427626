import React from 'react';
import './index.scss';
import TechImage1 from '../../../assets/images/techInfo1.svg'
import TechImage2 from '../../../assets/images/techInfo2.svg'
function Tech() {
    return <section className="tech" id="tech">
        <div className="container">
            <h3>Наши технологии</h3>
            <h4 className="hidden_xs hidden_md">Наш стек технологий быстрее и функциональнее стандартных решений на Django</h4>
            <div className="tech__content">
                <div className="tech__row">
                    <div className="tech__head">
                        Протокол
                    </div>
                    <div className="tech__cols">
                        <div className="tech__col tech__col-transparent hidden_xs hidden_md"></div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#FF002E" }}> </div>
                            <div className="tech__text">JSON RPC 2.0</div>
                        </div>
                        <div className="tech__col tech__col-transparent hidden_xs hidden_md"></div>
                    </div>
                </div>
                <div className="tech__row">
                    <div className="tech__head">
                        Взаимодействие
                    </div>
                    <div className="tech__cols">

                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#F2BD00" }}> </div>
                            <div className="tech__text">Redis streams</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#F2BD00" }}> </div>
                            <div className="tech__text">Kafka</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#F2BD00" }}> </div>
                            <div className="tech__text">Async HTTP</div>
                        </div>
                    </div>
                </div>
                <div className="tech__row">
                    <div className="tech__head">
                        Запуск
                    </div>
                    <div className="tech__cols">

                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#00D7F4" }}> </div>
                            <div className="tech__text">Блок сервисов</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#00D7F4" }}> </div>
                            <div className="tech__text">Кастомизация</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#00D7F4" }}> </div>
                            <div className="tech__text">Докеризация</div>
                        </div>
                    </div>
                </div>
                <div className="tech__row">
                    <div className="tech__head">
                        Хранение
                    </div>
                    <div className="tech__cols">
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#8F00FF" }}> </div>
                            <div className="tech__text">Elastic Search</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#8F00FF" }}> </div>
                            <div className="tech__text">Postgres</div>
                        </div>
                        <div className="tech__col">
                            <div className="tech__dot" style={{ background: "#8F00FF" }}> </div>
                            <div className="tech__text">Redis</div>
                        </div>
                    </div>


                </div>
                <div className="tech__row">
                    <div className="tech__head">
                        Ядро
                    </div>
                    <div className="tech__cols">
                        <div className="tech__col tech__col_black tech__col_block">
                            <div className="tech__text">Асинхронный Python 3</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="container">
            <div className="tech__info">
                <div className="tech__info-card">
                    <h6>Требования к ПО</h6>
                    <img src={TechImage1} alt="" />
                    <ul>
                        <li>DB: Postgres</li>
                        <li>ОС: Ubuntu 18.04+ <br />Astra Linux 2.12.40+</li>
                        <li>Cache: KeyDB 6.2.2</li>
                        <li>ОC контейнеров: Alpine 3.13 / Debian 11 (bullseye)</li>
                    </ul>
                </div>
                <div className="tech__info-card">
                    <h6>Технические Характеристики</h6>
                    <img src={TechImage2} alt="" />
                    <ul>
                        <li>CPU: 3-5 ядер</li>
                        <li>RAM: 4 GB</li>
                        <li>Disk: 6-12 GB</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
}
export default Tech