import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './index.scss';
import Man from '../../../assets/images/man2.png'

function Info() {
    return <section id="info" className="info">
        <div className="container with_padding">
            <div className="info__content">
                <h1>ELEMENTO <span className="text text_primary">PIM</span></h1>
                <p>Система централизованного управления информацией о товарах на разных языках и для различных каналов продаж</p>
                <img src={Man} className="hidden_lg man" alt=""/>
                <AnchorLink href="#form" offset="100" className="btn btn_primary btn_lg">Заказать демо</AnchorLink>
            </div>
        </div>
    </section>
}
export default Info