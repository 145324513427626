import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import Architecture from '../components/Architecture';
import Compare from '../components/Compare';
import Features from '../components/Features';
import Form from '../components/Form';
import Info from '../components/Info';
import Reviews from '../components/Reviews';
import Tasks from '../components/Tasks';
import Tech from '../components/Tech';
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';

function MainPage(props) {
    return <>
        <Header />
        <Info />
        <Tasks />
        <Architecture />
        <Features />
        <Compare />
        <Tech />
        {/*<Reviews />*/}
        <ToastProvider>
            <Form />
        </ToastProvider>
        <Footer />
    </>
}

export default MainPage