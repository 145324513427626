import React from "react";
import './index.scss'
import Logo from "../../../assets/images/logo.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="footer__inner">
                        <div className="footer__contacts">
                            <img className="footer__logo" src={Logo}/>
                            <div className="footer__contacts-item">
                                <div className="footer__contacts-key">
                                    АДРЕС
                                </div>
                                <div className="footer__contacts-value">
                                    121096, Москва, <br/> ул. Кастанаевская, 11
                                </div>
                            </div>
                            {/*<div className="footer__contacts-item">*/}
                            {/*    <div className="footer__contacts-key">*/}
                            {/*        ТЕЛЕФОН*/}
                            {/*    </div>*/}
                            {/*    <div className="footer__contacts-value">*/}
                            {/*        <a href="tel:+79999999999">+7(999) 999 99 99</a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="footer__contacts-item">
                                <div className="footer__contacts-key">
                                    E-MAIL
                                </div>
                                <div className="footer__contacts-value">
                                    <a href="mailto:info@elemento.systems">info@elemento.systems</a>
                                </div>
                            </div>
                            <div className="footer__copyright">
                                © 2022 Elemento Systems. Все права защищены.
                            </div>
                        </div>
                        <div>
                            <nav className="nav">
                                <ul className="nav__list">
                                    <AnchorLink
                                        offset="100"
                                        href="#tasks"
                                        className="nav__item"
                                    >
                                        Задачи продукта
                                    </AnchorLink>
                                    <AnchorLink
                                        offset="100"
                                        href="#arch"
                                        className="nav__item"
                                    >
                                        Архитектура
                                    </AnchorLink>
                                    <AnchorLink
                                        offset="100"
                                        href="#features"
                                        className="nav__item"
                                    >
                                        Возможности
                                    </AnchorLink>
                                    <AnchorLink
                                        offset="100"
                                        href="#reviews"
                                        className="nav__item"
                                    >
                                        Отзывы
                                    </AnchorLink>
                                    <a href="/public/Elemento_Mostro_PIM.pdf" download
                                       className="nav__item">Презентация</a>
                                </ul>
                            </nav>
                            <div className="footer__info">
                                <div className="footer__info-row">
                                    <p>Стоимость ПО от 1.3 млн рублей и предоставляется по запросу на почту <a
                                        href="mailto: info@elemento.systems">info@elemento.systems</a> или
                                        по <AnchorLink
                                            et="100"
                                            href="#form"
                                        >форме</AnchorLink>
                                    </p>
                                    <p>Поддержка: <br/> <a
                                        href="mailto:support@elemento.systems">support@elemento.systems</a></p>
                                </div>
                                <div className="footer__info-row">
                                    <a href="/public/Elemento_Mostro_Описание_функциональных_характеристик_Руководства.pdf"
                                       className="footer-info__link">Elemento Mostro. Описание функциональных характеристик. Руководство по установке. Руководство по эксплуатации</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
