import React from 'react';
import './index.scss';
import Box from "../../../assets/images/box.svg";
import Flash from "../../../assets/images/flash.svg";
import Time from "../../../assets/images/time.svg";
import Base from "../../../assets/images/base.svg";

function Tasks() {
    return <section id="tasks" className="tasks">
        <div className="container">
            <div className="tasks__block">
                <h3>Какие бизнес-задачи решает продукт?</h3>
                <div className="tasks__content">
                    <div className="tasks__item">
                        <div className="tasks__icon">
                            <img src={Box} alt="" />
                        </div>
                        <h5 className="tasks__heading">Единое окно хранения и редактирования</h5>
                        <p className="tasks__text">Данные больше  <span className="text text_primary">не потеряются</span> в excel-файлах, системах и на почте</p>
                    </div>
                    <div className="tasks__item">
                        <div className="tasks__icon">
                            <img src={Flash} alt="" />
                        </div>
                        <h5 className="tasks__heading">Эффективность работы контент-менеджеров</h5>
                        <p className="tasks__text">Контент-менеджеры обрабатывают <span className="text text_primary">в 5 раз</span> больше карточек</p>
                    </div>
                    <div className="tasks__item">
                        <div className="tasks__icon">
                            <img src={Time} alt="" />
                        </div>
                        <h5 className="tasks__heading">Сокращение времени на вывод в каналы продаж</h5>
                        <p className="tasks__text">Карточка товара выводится на сайт на <span className="text text_primary">50-80% быстрее</span></p>
                    </div>
                    <div className="tasks__item">
                        <div className="tasks__icon">
                            <img src={Base} alt="" />
                        </div>
                        <h5 className="tasks__heading">Чистота, полнота и актуальность данных</h5>
                        <p className="tasks__text">Качественный контент может увеличить конверсию <span className="text text_primary">на 35%</span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Tasks;